import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./AppRouter";
import { AuthProvider } from "./providers/AuthProvider";
import { AuthenticatedUserProvider } from "./providers/AuthenticatedUserProvider";
import { GlobalErrorHandlingProvider } from "./providers/GlobalErrorHandlingProvider";
import { PendoProvider } from "./providers/PendoProvider";
import { QueryClientProvider } from "./providers/QueryClientProvider";

function App() {
	return (
		<BrowserRouter>
			<GlobalErrorHandlingProvider>
				<AuthProvider>
					<AuthenticatedUserProvider>
						<PendoProvider>
							<QueryClientProvider>
								<AppRouter />
							</QueryClientProvider>
						</PendoProvider>
					</AuthenticatedUserProvider>
				</AuthProvider>
			</GlobalErrorHandlingProvider>
		</BrowserRouter>
	);
}

export default Sentry.withProfiler(App);
